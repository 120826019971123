var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _vm.formLoaded
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create mt-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.openUploadDocument },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("table-document", {
        ref: _vm.tableRef,
        attrs: {
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          onlyActions: ["destroy"],
          noInnerWidth: "",
        },
        on: { destroy: _vm.deleteDocument, download: _vm.downloadDocument },
      }),
      _vm._v(" "),
      _vm.formLoaded
        ? _c("add-document-modal", {
            attrs: {
              beFormCreate: _vm.beForm[_vm.repository],
              beRulesCreate: _vm.beRules[_vm.repository],
            },
            on: { upload: _vm.uploadDocument },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }